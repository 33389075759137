<template>
  <section class="agent-buttons">
    <!-- <a class="primary-button primary-button__new primary-button__new__agent" :href="agentNew" target="_blank">agent new</a> -->
    <a class="primary-button primary-button__classic primary-button__classic__agent" :href="agentClassic" target="_blank">agent classic</a>
  </section>
  <img  class="brand" src="../assets/images/backend-02-02.png" alt="wagerInternational">
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const agentClassic = "https://agents.betshop.ag/";
      const agentNew = "https://adm.betshop.ag/home/landing";
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
