<template>
<div class="mobile-menu" v-bind:class="{ 'hidden-mobile': !showComponent }">
    <div class="mobile-menu__buttons" @click="openModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24">
            <path id="user-solid" d="M10.5,12a6,6,0,1,0-6-6A6,6,0,0,0,10.5,12ZM8.358,14.25A8.356,8.356,0,0,0,0,22.608,1.392,1.392,0,0,0,1.392,24H19.608A1.392,1.392,0,0,0,21,22.608a8.356,8.356,0,0,0-8.358-8.358Z" fill="#1f1b18"/>
        </svg>
        <a href="#" class="btn-login secondary-button mobile-menu__login">login</a>
    </div>
    <menu-component/>
    <ModalClassic ref="modalClassic" />
</div>
</template>

<script>
    import MenuComponent from '@/components/menu.vue'
    import ModalClassic from '@/components/modalClassic.vue'
    export default {
        name: 'MobileMenu',
        showComponent: true,
        components: {
            MenuComponent,
            ModalClassic
        },
        methods: {
            openModal() {
                this.$refs.modalClassic.openModal();
            }
        },
    setup() {
      const DGS_SITEID = 595;
      const backendUrl = "betshop.ag";

      return {
        DGS_SITEID,
        backendUrl
      }
    },
        props: {
            items: {
            type: Array,
            required: true
            }
        },
        data() {
            return {
            isOpen: false
            }
        }
    };
</script>